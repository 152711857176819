import React from "react";
import Helmet from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
// import GTM from "react-tag-manager";

const TemplateWrapper = ({ children, isHome, isContact }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        {/* <GTM
          gtm={{
            id: "UA-143695347-1",
            auth: "", // Optional
            preview: "" // Optional
          }}
          settings={{
            sendPageView: true, // default false
            pageView: {
              // default null
              event: "pageview", // default
              data: {}, // default

              settings: {
                locationProp: "pathname", // default
                sendAs: "url" // default
              }
            }
          }}
        /> */}
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <Navbar isHome={isHome} />
      <div>{children}</div>
      <Footer isContact={isContact} />
    </div>
  );
};

export default TemplateWrapper;
