import React from "react";
import { Link } from "gatsby";
// import github from "../img/github-icon.svg";
import logo from "../img/unveil-logo.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  render() {
    return (
      <nav
        className={`unveil-nav ${this.props.isHome ? "unveil-nav--home" : ""}`}
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="unveil-nav__container">
            <div className="navbar-brand">
              <Link to="/" title="Logo">
                <img
                  src={logo}
                  alt="Unveil Production Group"
                  className="unveil-nav__logo"
                />
              </Link>
            </div>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-end">
                <Link className="navbar-item is-size-5" to="/about">
                  About
                </Link>
                <Link
                  className="navbar-item is-size-5 has-dropdown is-hoverable"
                  to="/services"
                >
                  Services
                  <div className="navbar-dropdown">
                    <Link className="navbar-item" to="/services/audio-visual">
                      Audio & Visual
                    </Link>
                    <Link className="navbar-item" to="/services/booth-rentals">
                      Booth Rentals
                    </Link>
                    <Link
                      className="navbar-item"
                      to="/services/display-rentals"
                    >
                      Display Rentals
                    </Link>
                    <Link
                      className="navbar-item"
                      to="/services/installation-dismantle"
                    >
                      Installation & Dismantle
                    </Link>
                    <Link
                      className="navbar-item"
                      to="/services/furniture-rentals"
                    >
                      Furniture Rentals
                    </Link>
                    <Link className="navbar-item" to="/services/display-sizes">
                      Display Sizes
                    </Link>
                  </div>
                </Link>
                <Link
                  className="navbar-item is-size-5 has-dropdown is-hoverable"
                  to="/our-work"
                >
                  Our Work
                  <div className="navbar-dropdown">
                    <Link
                      className="navbar-item"
                      to="/our-work/custom-exhibits"
                    >
                      Custom Built Exhibits
                    </Link>
                    <Link
                      className="navbar-item"
                      to="/our-work/corporate-events"
                    >
                      Corporate Events
                    </Link>
                    <Link className="navbar-item" to="/our-work/private-events">
                      Private Events
                    </Link>
                    <Link className="navbar-item" to="/our-work/conferences">
                      Conferences
                    </Link>
                    <Link className="navbar-item" to="/our-work/tradeshows">
                      Tradeshows
                    </Link>
                  </div>
                </Link>
                <Link className="navbar-item is-size-5" to="/blog">
                  Blog
                </Link>
                {/* Hiding for launch
                <Link className="navbar-item is-size-5" to="/resources">
                  Resources
                </Link> */}
                <Link className="navbar-item is-size-5" to="/events">
                  Events
                </Link>
                <Link className="navbar-item is-size-5" to="/contact">
                  Contact
                </Link>
              </div>
              {/* <div className="navbar-end has-text-centered">
              <a
                className="navbar-item"
                href="https://github.com/AustinGreen/gatsby-netlify-cms-boilerplate"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={github} alt="Github" />
                </span>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
