import React from "react";
import envelope from "../img/contact/envelope.svg";
import globe from "../img/contact/globe.svg";
import marker from "../img/contact/marker.svg";
import phone from "../img/contact/phone.svg";

const ContactBar = () => (
  <div className="columns is-multiline">
    {/* <div className="column is-one-quarter-desktop">
      <div className="columns is-vcentered is-mobile">
        <div className="column">
          <img src={marker} alt="Marker icon" style={{ height: "3em" }} />
        </div>
        <div className="column is-10">
          <p className="is-size-5">
            451 Mirror Ct., Suite E106
            <br />
            Henderson, NV 89011
          </p>
        </div>
      </div>
    </div> */}
    <div className="column is-one-third-desktop">
      <div className="columns is-vcentered is-mobile">
        <div className="column">
          <img src={phone} alt="Phone icon" style={{ height: "3em" }} />
        </div>
        <div className="column is-10">
          <p className="is-size-5">725.218.1577</p>
        </div>
      </div>
    </div>
    <div className="column is-one-third-desktop">
      <div className="columns is-vcentered is-mobile">
        <div className="column">
          <img src={envelope} alt="Email icon" style={{ height: "3em" }} />
        </div>
        <div className="column is-10">
          <p className="is-size-5">info@unveilproductiongroup.com</p>
        </div>
      </div>
    </div>
    <div className="column is-one-third-desktop">
      <div className="columns is-vcentered is-mobile">
        <div className="column">
          <img src={globe} alt="Internet Icon" style={{ height: "3em" }} />
        </div>
        <div className="column is-10">
          <p className="is-size-5">unveilproductiongroup.com</p>
        </div>
      </div>
    </div>
  </div>
);

export default ContactBar;
