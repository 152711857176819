import React from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class LocationMap extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   center: {
    //     lat: 36.0582835,
    //     lng: -115.0289327
    //   },
    //   zoom: 11
    // };
  }
  static defaultProps = {
    center: {
      lat: 36.0582835,
      lng: -115.0289327
    },
    zoom: 11
  };
  render() {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAB1khg2TeCwLci-vYI2SEXHPUd-Ixukkg" }}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
      >
        {/* <div
          className="marker"
          lat={this.props.center.lat}
          lng={this.props.center.lng}
          text="My Marker"
        /> */}
      </GoogleMapReact>
    );
  }
}

export default LocationMap;
