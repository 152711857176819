import React, { Fragment } from "react";
import { Link, StaticQuery } from "gatsby";

import logo from "../img/unveil-logo.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import linkedin from "../img/social/linkedin.svg";
// import newsletter from "../img/social/newsletter.svg";
import email from "../img/social/email.svg";

import LocationMap from "./LocationMap";
import ContactFormHubSpot from "./ContactFormHubSpot";
import ContactBar from "./ContactBar";

const Footer = ({ data, props }) => (
  <footer className="footer">
    <div className="container">
      <section className="section">
        {!props.isContact ? (
          <div className="columns">
            <div className="column is-12">
              <h4 className="is-size-1">Get in touch</h4>
            </div>
          </div>
        ) : null}
        <ContactBar />
      </section>
    </div>
    {!props.isContact ? (
      <div className="container">
        <section className="section">
          <div className="columns">
            <div className="column is-6">
              <ContactFormHubSpot />
            </div>
            <div className="column is-6">
              <div style={{ height: "400px", width: "100%" }}>
                <LocationMap />
              </div>
            </div>
          </div>
        </section>
      </div>
    ) : (
      <Fragment>
        <div style={{ height: "400px", width: "100%" }}>
          <LocationMap />
        </div>
        <div className="has-background-primary has-text-white section">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="columns is-vcentered">
                  <div className="column is-12 has-text-centered">
                    <h2 className="is-size-2">Let's Talk About Your Project</h2>
                    <Link
                      to="/needs-assessment/"
                      className="btn btn-outline btn-outline-white"
                    >
                      Take Our Free Needs Assessment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <section className="section">
            <div className="columns">
              <div className="column is-5">
                <h4 className="is-size-1">Get in touch</h4>
                <p className="is-size-5">
                  Our representatives are always available to discuss your
                  project one-on-one. Reach out with any and all questions so we
                  can create a solution tailored to your production needs.
                </p>
              </div>
              <div className="column is-6 is-offset-1">
                <ContactFormHubSpot />
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    )}

    <div className="content has-text-centered has-background-primary has-text-white-ter">
      <div className="container has-background-primary has-text-white-ter">
        <div className="columns is-multiline">
          <div className="column is-3">
            <ul className="footer-sitemap">
              <li className="footer-sitemap__item">
                <Link to="/" className="footer-sitemap__link">
                  Home
                </Link>
              </li>
              <li className="footer-sitemap__item">
                <Link to="/about" className="footer-sitemap__link">
                  About
                </Link>
              </li>
              {/* <li className="footer-sitemap__item">
                <Link to="/resources" className="footer-sitemap__link">
                  Resources
                </Link>
              </li> */}
              <li className="footer-sitemap__item">
                <Link to="/blog" className="footer-sitemap__link">
                  Blog
                </Link>
              </li>
              <li className="footer-sitemap__item">
                <Link to="/events" className="footer-sitemap__link">
                  Events
                </Link>
              </li>
              <li className="footer-sitemap__item">
                <Link to="/contact" className="footer-sitemap__link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="column is-3">
            <ul className="footer-sitemap">
              {data.services.edges.map((service, index) => (
                <li key={index} className="footer-sitemap__item">
                  <Link
                    to={service.node.fields.slug}
                    className="footer-sitemap__link"
                  >
                    {service.node.frontmatter.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="column is-3">
            <ul className="footer-sitemap">
              {data.ourWork.edges.map((ourWork, index) => (
                <li key={index} className="footer-sitemap__item">
                  <Link
                    to={ourWork.node.fields.slug}
                    className="footer-sitemap__link"
                  >
                    {ourWork.node.frontmatter.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="column is-3 has-text-centered">
            <img
              src={logo}
              alt="Unveil"
              style={{ width: "14em", height: "10em" }}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="columns is-vcentered">
              <div className="column is-12 has-text-centered social">
                <h4 className="has-text-centered is-size-4 has-text-white">
                  Connect with Us!
                </h4>
                <a
                  title="facebook"
                  href="https://www.facebook.com/UnveilLasVegas/"
                  target="blank"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </a>
                <a
                  title="twitter"
                  href="https://twitter.com/UnveilLasVegas"
                  target="blank"
                >
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </a>
                <a
                  title="instagram"
                  href="https://www.instagram.com/unveilproductiongroup/"
                  target="blank"
                >
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </a>
                <a
                  title="vimeo"
                  href="https://www.linkedin.com/company/unveil-production-group/"
                  target="blank"
                >
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </a>
                <a title="email" href="mailto:info@unveilproductiongroup.com">
                  <img
                    src={email}
                    alt="Email"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </a>
              </div>
              {/* <div className="column is-4 is-flex">
                <a className="social-icon-marg-r" title="Newsletter" href="/">
                  <img
                    src={newsletter}
                    alt="Newsletter"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </a>
                <h4 className="has-text-centered is-size-5 has-text-white">
                  Subscribe to our Newsletter
                </h4>
              </div> */}

              {/* <div className="column is-6 is-flex">
                <a
                  className="social-icon-marg-r"
                  title="email"
                  href="mailto:info@unveilproductiongroup.com"
                >
                  <img
                    src={email}
                    alt="Email"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                </a>
                <h4 className="has-text-centered is-size-5 has-text-white">
                  Email Us
                </h4>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

// export default Footer;

export default props => (
  <StaticQuery
    query={graphql`
      query {
        services: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { regex: "/service/" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
        ourWork: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { regex: "/ourWork/" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} props={props} />}
  />
);
