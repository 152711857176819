import React from "react";
import { navigate } from "gatsby-link";

export default function ContactForm() {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // https://legacydocs.hubspot.com/docs/methods/forms/submit_form
    // Create the new request
    var xhr = new XMLHttpRequest();
    var url = 'https://api.hsforms.com/submissions/v3/integration/submit/20046483/7e8bf786-f70b-4e98-8e6d-3496777ff4fc'

    // Example request JSON:
    var data = {
      "submittedAt": Date.now(),
      "fields": [
        {
          "name": "email",
          "value": state.email,
        },
        {
          "name": "company",
          "value": state.company,
        },
        {
          "name": "mobilephone",
          "value": state.mobilephone,
        },
        {
          "name": "firstname",
          "value": state.firstname,
        },
        {
          "name": "lastname",
          "value": state.lastname,
        },
        {
          "name": "message",
          "value": state.message,
        },
      ],
      "context": {
        // "hutk": "hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        // "pageUri": "www.example.com/page",
        "pageName": "Website Contact Form"
      },
    }

    var final_data = JSON.stringify(data)

    xhr.open('POST', url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = function() {
        if(xhr.readyState == 4 && xhr.status == 200) {
          navigate("/thanks")
          // alert(xhr.responseText); // Returns a 200 response if the submission is successful.
        } else if (xhr.readyState == 4 && xhr.status == 400){
            alert(xhr.responseText); // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState == 4 && xhr.status == 403){
            alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState == 4 && xhr.status == 404){
            alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
        }
       }


    // Sends the request

    xhr.send(final_data)


    // fetch("https://api.hsforms.com/submissions/v3/integration/submit/20046483/7e8bf786-f70b-4e98-8e6d-3496777ff4fc", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     submittedAt: Date.now(),
    //     fields: [
    //       {
    //         name: "email-input",
    //         value: state["email-input"],
    //       },
    //       {
    //         name: "company-input",
    //         value: state["company-input"],
    //       },
    //       {
    //         name: "mobilephone-input",
    //         value: state["mobilephone-input"],
    //       },
    //       {
    //         name: "firstname-input",
    //         value: state["firstname-input"],
    //       },
    //       {
    //         name: "lastname-input",
    //         value: state["lastname-input"],
    //       },
    //       {
    //         name: "message-input",
    //         value: state["message-input"],
    //       },
    //     ],
    //     "context": {
    //       "hutk": ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
    //       // "pageUri": "www.example.com/page",
    //       "pageName": "Website Contact Form"
    //     },
    //   })
    // })
    //   .then(() => navigate("/thanks"))
    //   .catch(error => alert(error));
  };

  return (
    <form
      name="contact"
      method="post"
      onSubmit={handleSubmit}
    >
      <div className="field">
        <label className="label is-size-6">Email:</label>
        <div className="control">
          <input
            id="email-input"
            className="input"
            type="email"
            name="email"
            placeholder="Email address"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label is-size-6">Company Name:</label>
        <div className="control">
          <input
            id="company-input"
            name="company"
            className="input"
            type="text"
            placeholder="Please enter your company name"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label is-size-6">Mobile Phone:</label>
        <div className="control">
          <input
            id="mobilephone-input"
            name="mobilephone"
            className="input"
            type="tel"
            placeholder="Please enter your mobile phone"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label is-size-6">First Name:</label>
        <div className="control">
          <input
            id="firstname-input"
            name="firstname"
            className="input"
            type="text"
            placeholder="Please enter your first name"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="field">
        <label className="label is-size-6">Last Name:</label>
        <div className="control">
          <input
            id="lastname-input"
            name="lastname"
            className="input"
            type="text"
            placeholder="Please enter your last name"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="field">
        <label className="label is-size-6">Message:</label>
        <div className="control">
          <textarea
            id="message-input"
            name="message"
            className="textarea"
            placeholder="Leave us a message"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <button className="btn">Submit</button>
        </div>
      </div>
    </form>
  );
}
